// @ts-nocheck
import Head from 'next/head'
import React from 'react'

const PageSeo = ({
  title = 'Skyclimbers - A Multiplayer City Building Action RPG',
  description = 'Build your kingdom, choose your class, and tame monsters in this Multiplayer City-Building/Open-World Action RPG that takes place in a procedurally generated anime-themed universe.',
  image = 'https://www.skyclimbers.net/images/sc_seo_img.jpg',

  schemaData,
}) => {
  return (
    <Head>
      <title>{title}</title>
      <script type="application/ld+json" />
      <meta content={title} name="title" />
      <meta content={description} name="description" />
      {schemaData ? (
        <script
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
          type="application/ld+json"
        />
      ) : null}
      <meta content="website" property="og:type" />
      <meta
        content={
          typeof window !== 'undefined' ? window.location.href : 'https://www.skyclimbers.net/'
        }
        property="og:url"
      />
      <meta content={title} property="og:title" />
      <meta content={description} property="og:description" />
      <meta content={image} property="og:image" />
      <meta content={image} property="og:image:secure_url" />

      <meta content="summary_large_image" name="twitter:card" />
      <meta
        content={
          typeof window !== 'undefined' ? window.location.href : 'https://www.skyclimbers.net/'
        }
        name="twitter:url"
      />
      <meta content={title} name="twitter:title" />
      <meta content={description} name="twitter:description" />
      <meta content={image} name="twitter:image" />
      <meta content="@playskyclimbers" name="twitter:site" />
      <meta content="@playskyclimbers" name="twitter:creator" />
    </Head>
  )
}

export default PageSeo
