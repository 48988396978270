import PageSeo from '@components/seo/PageSeo'
import * as gtag from '@operations/utils/gtag'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

const theme = {
  borderRadius: 8,
  colors: {
    black: '#000001',
    darkGold: '#827524',
    darkGrey: '#3c3c3c',
    darkTeal: '#00a7bd',
    darkerGold: '#554d18',
    gold: '#9f9924',
    grey: '#808080',
    lightGrey: '#d3d3d3',
    teal: '#00e1ff',
    white: '#fff',
    whiteSmoke: '#F5F5F5',
  },
  cursor: {
    default: 'url("/images/cursor/cursor_default.png"),default',
    pointer: 'url("/images/cursor/cursor_pointer.png"),pointer',
  },
  font: {
    fontFamily: {
      scText: 'SC',
      skyclimbers: 'Skyclimbers',
    },
    fontSize: {
      large: '150%',
      medium: '125%',
      regular: '100%',
      small: '75%',
      xLarge: '180%',
      xxLarge: '300%',
    },
    fontWeight: {
      bold: 'bold',
    },
    letterSpacing: '0.5px',
  },

  images: {
    darkPattern: '/images/bg_pattern.png',
    darkPatternRotate: '/images/bg_pattern_rotate.png',
    paratopeSkyclimbersLogo: '/images/paratope_skyclimbers_logo.png',
    sentiusPattern: '/images/sentipedia_pattern.png',
    whitePattern: '/images/bg_white_pattern.png',
  },
}

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    max-width: 100%;
    cursor: ${theme.cursor.default};
    background: ${theme.colors.white};
    letter-spacing: ${theme.font.letterSpacing};
  }
  a, button {
    cursor: ${theme.cursor.pointer};
  }
  * {
    box-sizing: border-box;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
    text-decoration: none;
    font-family: ${theme.font.fontFamily.scText};
  }
  div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    font-family: ${theme.font.fontFamily.scText};
    font-size: 100%;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  body, html {
    height: 100%;
  }
  #__next {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    min-height: 100%;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  button {
    appearance: none;
    font-family: ${theme.font.fontFamily.scText};
    font-size: ${theme.font.fontSize.medium};
  }
  input {
    font-family: ${theme.font.fontFamily.scText};
    font-size: ${theme.font.fontSize.regular};
  }
  h1, h2, h3, h4, h5, h6 {
    width: 100%;
    margin-bottom: 1rem;
  }
  h1 {
    font-size:200%;
    font-weight: 100;
  }
  h2 {
    font-size:150%;
    font-weight: 100;
  }
  h3 {
    font-size:120%;
  }
  h4 {
    font-size:100%;
  }
  p {
    margin-bottom: 1rem;
  }
  @media (min-width: 641px) {
    h1 {
      font-size:300%;
    }
    h2 {
      font-size:200%;
    }
    h3 {
      font-size:150%;
    }
    h4 {
      font-size:100%;
    }
    p {
      margin-bottom: 1rem;
    }
  }
`

export default function App({ Component, pageProps }) {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageView(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <PageSeo
          description={pageProps?.data?.page?.seo?.description || undefined}
          image={pageProps?.data?.page?.seo?.image?.formats?.large?.url || undefined}
          title={pageProps?.data?.page?.seo?.title || pageProps?.data?.page?.title || undefined}
        />
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  )
}
